async function getAllEdgeData(props) {
  const { talkData, edgeId, propsAPI } = props;
  let talkEdge;
  const editorEdge = propsAPI.find(edgeId);

  // Seleccionamos por el source y target posibles con los originales si ya se ha salvado
  // si no se ha salvado aparece con los ids de los clonados
  talkData.edges.forEach(element => {
    if (element.id === editorEdge.id) talkEdge = element;
  });
  return { talkEdge, editorEdge };
}

export default async function updateFromEdge(props) {
  const { form, languages } = props;
  const { setFields } = form;
  const { talkEdge, editorEdge } = await getAllEdgeData(props);
  if (talkEdge.talkParams.item && talkEdge.talkParams.keywords) {
    setFields({
      display_name: {
        value: editorEdge.model.label,
      },
      item: {
        value: talkEdge.talkParams.item,
      },
      kill: {
        checked: !!talkEdge.talkParams.kill,
      },
      alternative: {
        value: talkEdge.isAlternative ? 2 : 1,
      },
    });

    languages.forEach(lang => {
      const description = `description_${lang}`;
      const keywords = `keywords_${lang}`;
      setFields({
        [description]: { value: talkEdge.talkParams.description[lang] },
        [keywords]: { value: talkEdge.talkParams.keywords[lang] },
      });
    });
  } else {
    setFields({
      display_name: {
        value: '',
      },
      item: {
        value: '',
      },
      kill: {
        value: false,
      },
      alternative: {
        value: 1,
      },
    });

    languages.forEach(lang => {
      const description = `description_${lang}`;
      const keywords = `keywords_${lang}`;
      setFields({
        [description]: { value: '' },
        [keywords]: { value: '' },
      });
    });
  }

  return talkEdge;
}
