// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pcmmB9cSoCXTk7SJO3CzH {\n  display: flex;\n  align-items: center;\n}\n.pcmmB9cSoCXTk7SJO3CzH .command i {\n  display: inline-block;\n  width: 27px;\n  height: 27px;\n  margin: 0 6px;\n  padding-top: 6px;\n  text-align: center;\n  border: 1px solid #fff;\n  cursor: pointer;\n}\n.pcmmB9cSoCXTk7SJO3CzH .command i:hover {\n  border: 1px solid #e6e9ed;\n}\n.pcmmB9cSoCXTk7SJO3CzH .disable i {\n  color: rgba(0, 0, 0, 0.25);\n  cursor: auto;\n}\n.pcmmB9cSoCXTk7SJO3CzH .disable i:hover {\n  border: 1px solid #fff;\n}\n.__8D4ZcLMJU2xWTJ5hnbg .ant-tooltip-inner {\n  font-size: 12px;\n  border-radius: 0;\n}\n.OcLWHGBeMatjmBsNeIVYj {\n  font-family: 'Righteous', cursive;\n  font-size: 15px;\n}\n.OcLWHGBeMatjmBsNeIVYj img {\n  width: 35px;\n}\n", ""]);
// Exports
exports.locals = {
	"toolbar": "pcmmB9cSoCXTk7SJO3CzH",
	"tooltip": "__8D4ZcLMJU2xWTJ5hnbg",
	"logo": "OcLWHGBeMatjmBsNeIVYj"
};
module.exports = exports;
