/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import App from '../App';
import Auth from '../Auth';

const Root = ({ store }) => {
  const authCheck = (Component, props) => {
    const state = store.getState();
    return state.editor.isSignedIn ? (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...props} />
    ) : (
      <Auth />
    );
  };

  return (
    <Provider store={store}>
      <Router>
        <Route path="/" exact render={authCheck.bind(this, App)} />
        <Route path="/auth" exact component={Auth} />
      </Router>
    </Provider>
  );
};

Root.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired,
};

export default Root;
