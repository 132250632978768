/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Alert } from 'antd';
import { closeSettingsDrawer } from '../../actions/editor';
import Explorer from '../EditorExplorer';

// eslint-disable-next-line react/prop-types
function EditorSettings({
  settingsDrawerVisible,
  goCloseSettingsDrawer,
  selectedBot,
  selectedCanvas,
}) {
  const isCloseable = !!selectedBot && !!selectedCanvas;

  const AlertMsgSelection = !isCloseable ? (
    <Alert
      message="Select Domain and Canvas"
      description="To start creating or editing you need to select a domain and a canvas first."
      type="warning"
      showIcon
    />
  ) : (
    <></>
  );

  return (
    <Drawer
      title="General Settings"
      placement="left"
      visible={settingsDrawerVisible}
      width={510}
      onClose={() => goCloseSettingsDrawer()}
      closable={isCloseable} // eslint-disable-next-line react/jsx-closing-bracket-location
      maskClosable={isCloseable}
    >
      <Explorer />
      {AlertMsgSelection}
    </Drawer>
  );
}

const mapStateToProps = state => ({
  settingsDrawerVisible: state.editor.settingsDrawerVisible,
  selectedBot: state.bots.selectedBot,
  selectedCanvas: state.activeCanvas.selectedCanvas,
});

const mapDispatchToProps = dispatch => ({
  goCloseSettingsDrawer: () => dispatch(closeSettingsDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditorSettings);
