export default function getStepConnection(edgeTalkData, editorNodeItems) {
  let nextStep = edgeTalkData.target;
  editorNodeItems.forEach(element => {
    if (element.id === nextStep) {
      if (element.originalItemId) nextStep = element.originalItemId;
    }
  });

  const stepConnection = {
    ...edgeTalkData.talkParams,
    nextStep,
    id: edgeTalkData.id,
  };

  return { stepConnection };
}
