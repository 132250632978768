/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Row, Col } from 'antd';
import GGEditor, { Flow, RegisterCommand } from 'gg-editor';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import FlowContextMenu from '../EditorContextMenu';
import FlowToolbar from '../EditorToolbar';
import { FlowItemPanel } from '../EditorItemPanel';
import Notifications from '../EditorNotifications';
import EditorOpts from '../EditorItemOpts';
import EditorCheckpointModal from '../EditorCheckpointModal';
import EditorSettings from '../EditorSettings';
import ErrorBoundary from '../EditorError';
import EditorCommander from '../EditorCommander';

import styles from './index.less';

const App = ({ data }) => {
  GGEditor.setTrackable(false);
  const [editorCommand, setCommand] = useState(undefined);
  const [clickInItem, setClickInItem] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (params.client && params.canvas) {
      console.log('params', params);
    }
  }, []);

  const handleClick = e => {
    setClickInItem(undefined);
    setClickInItem(e.item.model);
  };

  return (
    <div>
      <GGEditor
        className={styles.editor}
        onAfterCommandExecute={({ command }) => {
          setCommand(command);
          // stepMan.checkNodeEvent(command);
        }}
      >
        <Row type="flex" className={styles.editorHd}>
          <Col span={24}>
            <FlowToolbar />
          </Col>
        </Row>
        <Row type="flex" className={styles.editorBd}>
          <Col span={4} className={styles.editorSidebar}>
            <FlowItemPanel />
          </Col>
          <Col span={20} className={styles.editorContent}>
            <Flow
              className={styles.flow}
              data={data}
              onDoubleClick={handleClick}
            />
            <RegisterCommand name="edit" />
          </Col>
        </Row>
        <FlowContextMenu />
        <Notifications />
        <EditorCheckpointModal />
        <EditorOpts />
        <EditorSettings />
        <ErrorBoundary />
        <EditorCommander command={editorCommand} clickInItem={clickInItem} />
      </GGEditor>
    </div>
  );
};

const mapStateToProps = state => ({
  data: {
    nodes: state.activeCanvas.nodes,
    edges: state.activeCanvas.edges,
  },
});

export default connect(mapStateToProps)(hot(App));
