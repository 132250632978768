import isEmpty from 'lodash.isempty';
import hasIn from 'lodash.hasin';

async function getAllNodeData(props) {
  const { editorItems, talkData, nodeSelectedId, canvasList } = props;
  const { nodes } = talkData;
  let talkNode;
  let editorNode;

  editorItems.nodes.forEach(element => {
    if (element.id === nodeSelectedId) editorNode = element;
  });

  nodes.forEach(element => {
    if (element.stepId === nodeSelectedId) talkNode = element;
  });

  if (!editorNode) {
    canvasList.forEach(canvas => {
      canvas.data.editorItems.nodes.forEach(node => {
        if (node.id === nodeSelectedId) editorNode = node;
      });
    });
  }

  return { talkNode, editorNode };
}

export default async function updateFromNode(props) {
  const { form, nodeSelectedType } = props;
  const { setFields } = form;
  const { talkNode, editorNode } = await getAllNodeData(props);

  if (!editorNode || !talkNode) return false;

  if (nodeSelectedType === 'open' || nodeSelectedType === 'closed') {
    setFields({
      display_name: {
        value: editorNode.label,
      },
      name: {
        value: talkNode.params.name || '',
      },
      validator: {
        value: talkNode.params.validator || '',
      },
      entity_name: {
        value: talkNode.params.entity.name || '',
      },
      entity_type: {
        value: talkNode.params.entity.type || '',
      },
    });
  }

  if (nodeSelectedType === 'action' || nodeSelectedType === 'webhook') {
    setFields({
      display_name: {
        value: editorNode.label,
      },
      name: { value: talkNode.params.name },
    });
  }

  if (nodeSelectedType === 'end') {
    setFields({
      display_name: {
        value: editorNode.label || '',
      },
      intent_name: { value: talkNode.params.name || '' },
      client_finish_conversation: {
        value:
          talkNode.params.action.actionParams.finishClientConversation || false,
      },
    });
  }

  if (nodeSelectedType === 'start') {
    let intentName = '';
    if (talkNode.params.action.actionParams) {
      intentName = talkNode.params.action.actionParams.intent;
    }

    setFields({
      display_name: {
        value: editorNode.label,
      },
      intent_name: { value: intentName },
    });
  }

  if (nodeSelectedType === 'message') {
    setFields({
      name: {
        value: talkNode.params.name,
      },
      display_name: {
        value: editorNode.label,
      },
    });
  }

  if (nodeSelectedType === 'clarification') {
    setFields({
      name: {
        value: talkNode.params.name,
      },
      display_name: {
        value: editorNode.label,
      },
      repeatLastBotQuestion: {
        value: talkNode.params.repeatLastBotQuestion,
      },
    });
  }

  if (hasIn(talkNode.params, 'text')) {
    if (isEmpty(talkNode.params.text) && nodeSelectedType !== 'action') {
      setFields({
        text: {
          value: '',
        },
      });
    } else {
      Object.keys(talkNode.params.text).forEach(k => {
        setFields({
          [k]: {
            value: talkNode.params.text[k],
          },
        });
      });
    }
  }

  return talkNode;
}
