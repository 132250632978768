/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import moment from 'moment';
import { ItemPanel } from 'gg-editor';

import FlowItemMenu from './FlowItemMenu';
import pkg from '../../../package.json';

import styles from './index.less';

const FlowItemPanel = () => {
  useEffect(() => {
    /**
     * Fix canvas height bug
     */
    const currentCanvasHeight = document.getElementsByTagName('canvas')[0]
      .height;

    if (currentCanvasHeight < window.screen.availHeight) {
      document.getElementsByTagName('canvas')[0].height =
        window.screen.availHeight;
    }
  });

  return (
    <ItemPanel className={styles.itemPanel}>
      <FlowItemMenu />
      <div style={{ position: 'fixed', bottom: 0, left: 10 }}>
        <span className={styles.author}>
          By Rational Touch @ {moment().format('YYYY')}
        </span>
        <span className={styles.version}>v{pkg.version}-Beta</span>
      </div>
    </ItemPanel>
  );
};

export default FlowItemPanel;
