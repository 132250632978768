/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Form, Button, Col, Row, Input, Divider, Checkbox } from 'antd';

export default function Checkpoint({
  getFieldDecorator,
  goCloseDrawer,
  hasErrors,
  getFieldsError,
  updateFormData,
}) {
  useEffect(() => {
    updateFormData();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Intent Name">
            {getFieldDecorator('intent_name', {
              rules: [{ required: false, message: 'Please enter intent name' }],
            })(<Input placeholder="Please enter intent name" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Display Name">
            {getFieldDecorator('display_name', {
              rules: [{ required: true, message: 'Please enter display name' }],
            })(
              <Input
                style={{ width: '100%' }}
                placeholder="Please enter display name"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Do you want to end the conversation at this point?">
            {getFieldDecorator('client_finish_conversation', {
              valuePropName: 'checked',
              initialValue: false,
              rules: [{ required: false, message: 'Please select choice' }],
            })(<Checkbox>Finish Client Conversation</Checkbox>)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Button type="default" onClick={goCloseDrawer}>
              Close
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
