/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';

class Notifications extends React.Component {
  componentDidUpdate() {
    const {
      bots_isDeleting,
      bots_isFetching,
      bots_isPushing,
      bots_msgKO,
      bots_msgOK,
      bots_msgText,
      canvas_isDeleting,
      canvas_isPushing,
      canvas_msgKO,
      canvas_msgOK,
      canvas_msgText,
    } = this.props;

    if (bots_isDeleting) message.loading('Deleting bot...');
    if (bots_isFetching) message.loading('Getting bots...');
    if (bots_isPushing) message.loading('Creating bot ...');
    if (bots_msgKO) message.error(bots_msgText);
    if (bots_msgOK) message.success(bots_msgText);

    if (canvas_isDeleting) message.loading('Deleting canvas...', 1);
    // if (canvas_isFetching) message.loading('Getting canvas...', 1);
    if (canvas_isPushing) message.loading('Loading canvas ...', 1);
    if (canvas_msgKO) message.error(canvas_msgText);
    if (canvas_msgOK) message.success(canvas_msgText);
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = state => ({
  bots_isFetching: state.bots.isFetching,
  bots_isPushing: state.bots.isPushing,
  bots_isDeleting: state.bots.isDeleting,
  bots_msgOK: state.bots.msgOK,
  bots_msgKO: state.bots.msgKO,
  bots_msgText: state.bots.msgText,
  canvas_isFetching: state.canvas.isFetching,
  canvas_isPushing: state.canvas.isPushing,
  canvas_isDeleting: state.canvas.isDeleting,
  canvas_msgOK: state.canvas.msgOK,
  canvas_msgKO: state.canvas.msgKO,
  canvas_msgText: state.canvas.msgText,
});

export default connect(mapStateToProps)(Notifications);
