import { notification } from 'antd';
import React from 'react';

class ErrorBoundary extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // this.state = { hasError: false };
  // }

  componentDidCatch(error, info) {
    // Display fallback UI
    // this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    notification.error({
      message: error,
      description: info,
    });
  }

  render() {
    return <></>;
  }
}

export default ErrorBoundary;
