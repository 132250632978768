export default async function transformStepsReceived(json) {
  const steps = json.steps || [];
  const edgeList = [];
  steps.forEach(item => {
    if (item.params.options) {
      item.params.options.forEach(opt => {
        const newEdge = {
          source: item.stepId,
          isAlternative: false,
          changeStatus: 'not_changed', // not_changed, updated, deleted
        };
        newEdge.target = opt.nextStep;
        newEdge.talkParams = opt;
        newEdge.id = opt.id;
        delete newEdge.talkParams.id;
        edgeList.push(newEdge);
      });
    }
    if (item.params.alternatives) {
      item.params.alternatives.forEach(alt => {
        const newEdge = {
          source: item.stepId,
          isAlternative: false,
          changeStatus: 'not_changed', // not_changed, updated, deleted
        };
        newEdge.target = alt.nextStep;
        newEdge.talkParams = alt;
        newEdge.isAlternative = true;
        newEdge.id = alt.id;
        delete newEdge.talkParams.id;
        edgeList.push(newEdge);
      });
    }
  });

  return edgeList;
}
