function activeCanvas(
  state = {
    nodes: [],
    edges: [],
    selectedCanvas: undefined,
    name: undefined,
  },
  action,
) {
  switch (action.type) {
    case 'LOAD_CANVAS_ITEMS':
      return {
        ...state,
        nodes: action.items ? action.items.nodes : [],
        edges: action.items ? action.items.edges : [],
        selectedCanvas: action.id,
        name: action.name || '',
      };
    case 'ADD_CLONED_NODE_CHECKPOINT':
      return {
        ...state,
        nodes: [
          ...state.nodes.filter(node => node.id !== action.checkpointItem.id),
          action.checkpointItem,
        ],
      };
    default:
      return state;
  }
}

export default activeCanvas;
