// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3mR4_nZH9psSH_FKGtpovx {\n  flex: 1;\n  background: #fdfdfd;\n  width: 190px;\n}\n._3mR4_nZH9psSH_FKGtpovx .ant-card {\n  background: #fdfdfd;\n}\n._3mR4_nZH9psSH_FKGtpovx .ant-card-body {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n._3mR4_nZH9psSH_FKGtpovx .ant-card-body > div {\n  margin-bottom: 16px;\n}\n._1bp2wgQDAceSYh0UglQpJc {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: 10px;\n}\n._26JXulPHdjcrSujwjvLYi8 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: 8px;\n  color: lightgray;\n}\n", ""]);
// Exports
exports.locals = {
	"itemPanel": "_3mR4_nZH9psSH_FKGtpovx",
	"author": "_1bp2wgQDAceSYh0UglQpJc",
	"version": "_26JXulPHdjcrSujwjvLYi8"
};
module.exports = exports;
