/* eslint-disable react/prop-types */
import React from 'react';
import { withPropsAPI } from 'gg-editor';
import { connect } from 'react-redux';

import { loadCanvasItems } from '../../actions/activeCanvas';
import { deleteTalkNode, addEdge, edgeUpdate } from '../../actions/nodes';
import {
  selectedNode,
  selectedEdge,
  closeDrawer,
  openDrawer,
} from '../../actions/editor';

import addNode from './addNode';
import selectNode from './selectNode';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastAddId: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { command, domainId, dispatch, clickInItem } = this.props;
    if (prevProps.command !== command) {
      if (command) this.analyzeCommand(command, domainId, dispatch);
    }
    if (prevProps.clickInItem !== clickInItem && !!clickInItem) {
      this.openDrawerWhenClickItem();
    }
  }

  async openDrawerWhenClickItem() {
    const {
      clickInItem,
      goSelectNode,
      goSelectEdge,
      canvasItems,
      talkData,
      drawerStatus,
      goOpenDrawer,
      goCloseDrawer,
    } = this.props;
    const isEdge = !!(clickInItem.target && clickInItem.source);

    const {
      _isEdge,
      itemId,
      stepType,
      cloned,
      source,
      target,
    } = await selectNode(
      clickInItem.id,
      isEdge,
      clickInItem,
      talkData.nodes,
      canvasItems.nodes,
    );
    if (!_isEdge) goSelectNode(itemId, stepType, cloned);
    else goSelectEdge(itemId, source, target);

    if (drawerStatus === 'closed') goOpenDrawer();
    else goCloseDrawer();
  }

  updateCanvasEdge(command) {
    const { talkData, goUpdateEdge } = this.props;
    talkData.edges.forEach(edge => {
      if (edge.id === command.itemId) {
        const updatedEdge = edge;
        if (command.updateModel.target)
          updatedEdge.target = command.updateModel.target;
        else updatedEdge.source = command.updateModel.source;

        goUpdateEdge(updatedEdge);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  analyzeCommand(command, domainId, dispatch) {
    const { lastAddId } = this.state;

    const {
      goLoadCanvasItems,
      selectedCanvas,
      propsAPI,
      talkData,
      goDeleteTalkNode,
      goAddEdge,
    } = this.props;
    if (
      command.name === 'add' &&
      command.type === 'node' &&
      lastAddId !== command.addId
    ) {
      this.setState({ lastAddId: command.addId });
      goLoadCanvasItems(selectedCanvas, propsAPI.save());
      return addNode(command.addModel, dispatch, domainId);
    }

    if (
      command.name === 'add' &&
      command.type === 'edge' &&
      lastAddId !== command.addId
    ) {
      goAddEdge(command.addModel);
      goLoadCanvasItems(selectedCanvas, propsAPI.save());
    }

    if (command.name === 'delete') {
      const itemToDeleteId = command.itemIds[0];
      talkData.nodes.forEach(element => {
        if (element.stepId === itemToDeleteId) {
          goDeleteTalkNode(itemToDeleteId);
        }
      });

      goLoadCanvasItems(selectedCanvas, propsAPI.save());
    }

    if (command.name === 'update') this.updateCanvasEdge(command);

    return {};
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = state => ({
  domainId: state.bots.selectedBot,
  selectedCanvas: state.activeCanvas.selectedCanvas,
  canvasItems: {
    nodes: state.activeCanvas.nodes,
    edges: state.activeCanvas.edges,
  },
  talkData: state.nodes.talkData,
  drawerStatus: state.editor.editorStatus,
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => dispatch(action),
  goLoadCanvasItems: (id, items, name) =>
    dispatch(loadCanvasItems(id, items, name)),
  goDeleteTalkNode: id => dispatch(deleteTalkNode(id)),
  goAddEdge: edge => dispatch(addEdge(edge)),
  goSelectNode: (id, talkType, cloned) =>
    dispatch(selectedNode(id, talkType, cloned)),
  goSelectEdge: (id, source, target) =>
    dispatch(selectedEdge(id, source, target)),
  goCloseDrawer: () => dispatch(closeDrawer()),
  goOpenDrawer: () => dispatch(openDrawer()),
  goUpdateEdge: edge => dispatch(edgeUpdate(edge)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropsAPI(Component));
