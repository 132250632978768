export function loadCanvasItems(id, items, name) {
  return {
    type: 'LOAD_CANVAS_ITEMS',
    items: items || { nodes: undefined, edges: undefined },
    id,
    name,
  };
}

export function addClonedNodeCheckpoint(checkpointItem) {
  return {
    type: 'ADD_CLONED_NODE_CHECKPOINT',
    checkpointItem,
  };
}
