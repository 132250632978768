/* eslint-disable no-param-reassign */
export default async function updateEdge(values, props) {
  const {
    talkData,
    edgeId,
    goCloseDrawer,
    languages,
    propsAPI,
    goEdgeUpdate,
  } = props;

  const langDescription = {};
  const langKeywords = {};
  const editorEdge = propsAPI.find(edgeId);

  languages.forEach(lang => {
    const description = values[`description_${lang}`];

    const keywords = values[`keywords_${lang}`];

    if (description) {
      langDescription[lang] = description;
      values.description = langDescription;
      delete values[`description_${lang}`];
    }
    if (keywords) {
      langKeywords[lang] = keywords;
      values.keywords = langKeywords;
      delete values[`keywords_${lang}`];
    }
  });

  talkData.edges.forEach(talkDataEdge => {
    if (talkDataEdge.id === editorEdge.model.id) {
      if (values.alternative === 1) talkDataEdge.isAlternative = false;
      else talkDataEdge.isAlternative = true;

      talkDataEdge.talkParams.kill = values.kill || false;
      delete values.alternative;
      delete values.kill;

      talkDataEdge.talkParams = {
        ...talkDataEdge.talkParams,
        ...values,
      };

      if (talkDataEdge.isAlternative) {
        const displayNameCleaned = values.display_name.replace('🔶', '');
        editorEdge.model.label = `🔶 ${displayNameCleaned}`;
      } else editorEdge.model.label = values.display_name.replace('🔶', '');

      propsAPI.update(editorEdge, editorEdge.model);

      goEdgeUpdate(talkDataEdge);
      goCloseDrawer();
    }
  });
}
