function bots(
  state = {
    isFetching: false,
    isPushing: false,
    isDeleting: false,
    items: [],
    selectedBot: undefined,
    msgOK: false,
    msgKO: false,
    msgText: '',
    languages: [],
  },
  action,
) {
  const { domainName, flowId, languages } = action;
  switch (action.type) {
    case 'ADD_BOT':
      return {
        ...state,
        items: [
          ...state.items,
          {
            flowId,
            domainName,
          },
        ],
        isPushing: true,
        selectedBot: flowId,
        languages,
      };
    case 'SELECT_BOT':
      return {
        ...state,
        selectedBot: action.flowId,
        languages: action.languages,
      };
    case 'REQUEST_BOTS':
      return { ...state, isFetching: true };
    case 'RECEIVE_BOTS':
      return {
        ...state,
        isFetching: false,
        items: action.bots,
        lastUpdate: action.receivedAt,
      };
    case 'IS_DELETING':
      return { ...state, isDeleting: true };
    case 'DELETED_BOT_ERROR':
      return {
        ...state,
        isDeleting: false,
        msgKO: true,
        msgText: 'Error deleting bot',
      };
    case 'DELETED_BOT':
      return {
        ...state,
        items: [...state.items.filter(item => item.flowId !== action.flowId)],
        isDeleting: false,
        msgOK: true,
        msgText: 'Bot Deleted!',
        selectedBot: undefined,
      };
    case 'SELECTED_LANGUAGES':
      return { ...state, languages: action.languages };
    default:
      return state;
  }
}

export default bots;
