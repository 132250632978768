export function openDrawer() {
  return {
    type: 'OPEN_DRAWER',
  };
}

export function closeDrawer() {
  return {
    type: 'CLOSE_DRAWER',
  };
}

export function selectedNode(id, talkType, cloned = false) {
  return {
    type: 'SELECTED_NODE',
    id,
    talkType,
    cloned,
  };
}

export function selectedEdge(edgeId, parentId, targetId) {
  return {
    type: 'SELECTED_EDGE',
    edgeId,
    parentId,
    targetId,
  };
}

export function closeSettingsDrawer() {
  return {
    type: 'CLOSE_SETTINGS_DRAWER',
  };
}

export function saveCanvasRequest() {
  return {
    type: 'SAVE_CANVAS_REQUEST',
  };
}

export function savedCanvasDone() {
  return {
    type: 'SAVE_CANVAS_DONE',
  };
}

export function showCheckpointSelector(show) {
  return {
    type: 'SHOW_CHECKPOINT_SELECTOR',
    show,
  };
}

export function isSignedIn(value, name) {
  return {
    type: 'IS_SIGNED_IN',
    value,
    name,
  };
}
