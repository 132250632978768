import { combineReducers } from 'redux';

import bots from './bots';
import canvas from './canvas';
import nodes from './nodes';
import editor from './editor';
import hooks from './hooks';
import activeCanvas from './activeCanvas';

const rootReducers = combineReducers({
  activeCanvas,
  bots,
  canvas,
  editor,
  hooks,
  nodes,
});

export default rootReducers;
