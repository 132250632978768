export default function findEdgeTalkData(id, talkDataEdges) {
  let edgeTalkData;

  talkDataEdges.forEach(edge => {
    if (edge.id === id) {
      edgeTalkData = edge;
    }
  });

  return { edgeTalkData };
}
