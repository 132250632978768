import * as nodeActions from '../../actions/nodes';
import * as editorActions from '../../actions/editor';

export default async function addNodeModule(addModel, dispatch, domainId) {
  switch (addModel.label) {
    case 'Start':
      dispatch(
        nodeActions.addNodeCheckPoint(null, domainId, addModel, 'start'),
      );
      break;
    case 'Checkpoint':
      dispatch(nodeActions.selectNodeCheckPoint(null, domainId, addModel));
      return dispatch(editorActions.showCheckpointSelector(true));
    case 'End':
      dispatch(nodeActions.addNodeCheckPoint(null, domainId, addModel, 'end'));
      break;
    case 'Open':
      dispatch(
        nodeActions.addNodeQuestion(
          null,
          'open',
          {},
          null,
          null,
          domainId,
          addModel,
        ),
      );
      break;
    case 'Closed':
      dispatch(
        nodeActions.addNodeQuestion(
          null,
          'closed',
          {},
          null,
          null,
          domainId,
          addModel,
        ),
      );
      break;
    case 'Custom':
      dispatch(nodeActions.addNodeAction(null, 'custom', domainId, addModel));
      break;
    case 'Webhook':
      dispatch(
        nodeActions.addNodeAction(
          null,
          'webhook',
          domainId,
          addModel,
          'webhook',
        ),
      );
      break;
    case 'Task':
      dispatch(nodeActions.addNodeAction(null, 'task', domainId, addModel));
      break;
    case 'Message':
      dispatch(
        nodeActions.addNodeOutput(null, 'message', {}, domainId, addModel),
      );
      break;
    case 'Clarification':
      dispatch(
        nodeActions.addNodeOutput(
          null,
          'clarification',
          {},
          domainId,
          addModel,
        ),
      );
      break;
    default:
      return false;
  }
  return true;
}
