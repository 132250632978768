/* eslint-disable react/prop-types */
import React from 'react';
import { Button, notification } from 'antd';
import { withPropsAPI } from 'gg-editor';
import { connect } from 'react-redux';
import uniq from 'lodash.uniq';

import { pushCanvas } from '../../../../actions/canvas';
import { pushStep, deleteStep, fetchSteps } from '../../../../actions/nodes';
import ifCheckpoint from '../../../../common/ifCheckpoint';

import getClonedIds from './getClonedIds';
import buildStepConnections from './buildStepConnections';

import styles from './index.less';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      buttonText: 'save',
      snapShot: false,
    };
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({ message, description });
  };

  async saveCanvas() {
    const {
      propsAPI,
      canvasState,
      activeCanvasState,
      goPushCanvas,
    } = this.props;
    const { selectedCanvas } = activeCanvasState;
    let currentCanvasItem;
    canvasState.items.forEach(canvasItem => {
      if (canvasItem.canvasId === selectedCanvas)
        currentCanvasItem = canvasItem;
    });

    const snapShot = propsAPI.save();
    this.setState({ snapShot });
    const { name, botId, canvasId } = currentCanvasItem;
    const data = { editorItems: snapShot };
    goPushCanvas(name, botId, canvasId, data);
    return true;
  }

  async runSaveEngine() {
    const { snapShot } = this.state;
    if (!snapShot.nodes) return false;
    const { talkData, goPushStep, canvasState, activeCanvasState } = this.props;
    let nodesIdToUpdate = snapShot.nodes
      .filter(node => !node.originalItemId)
      .map(node => node.id);

    nodesIdToUpdate = nodesIdToUpdate.concat(
      snapShot.nodes
        .filter(node => node.originalItemId)
        .map(node => node.originalItemId),
    );

    nodesIdToUpdate = uniq(nodesIdToUpdate);

    let allNodeCanvas = [];
    let allEdgeCanvas = [];

    canvasState.items.forEach(canvasItem => {
      if (canvasItem.canvasId === activeCanvasState.selectedCanvas)
        allNodeCanvas = allNodeCanvas.concat(snapShot.nodes || []);
      else if (canvasItem.data.editorItems.nodes)
        allNodeCanvas = allNodeCanvas.concat(
          canvasItem.data.editorItems.nodes || [],
        );
    });

    canvasState.items.forEach(canvasItem => {
      if (canvasItem.canvasId === activeCanvasState.selectedCanvas)
        allEdgeCanvas = allEdgeCanvas.concat(snapShot.edges || []);
      else {
        allEdgeCanvas = allEdgeCanvas.concat(
          canvasItem.data.editorItems.edges || [],
        );
      }
    });

    nodesIdToUpdate.forEach(async nodeId => {
      let involvedNodeIds = [];
      let stepBody = {};

      if (ifCheckpoint(nodeId, talkData.nodes)) {
        const { clonedIds } = await getClonedIds(nodeId, allNodeCanvas);
        involvedNodeIds = involvedNodeIds.concat(clonedIds);
      }
      involvedNodeIds.push(nodeId);

      talkData.nodes.forEach(talkDataNode => {
        if (talkDataNode.stepId === nodeId) {
          stepBody = { ...talkDataNode };
          stepBody.params.options = [];
          stepBody.params.alternatives = [];
        }
      });

      const { step } = await buildStepConnections(
        allEdgeCanvas,
        involvedNodeIds,
        allNodeCanvas,
        talkData,
        stepBody,
      );
      console.log(' ========= STEP ==========');
      console.log(step);
      goPushStep(step.flowId, step.stepId, step.params);
    });

    return true;
  }

  async deleteStepBulk() {
    const { talkData, goDeleteStep, canvasState, propsAPI } = this.props;
    const allNodeCanvas = [];
    canvasState.items.forEach(canvas => {
      if (canvas.data.editorItems.nodes) {
        canvas.data.editorItems.nodes.forEach(node => {
          allNodeCanvas.push(node.id);
        });
      }
    });
    const snapShot = propsAPI.save();
    if (snapShot.nodes) {
      snapShot.nodes.forEach(node => {
        allNodeCanvas.push(node.id);
      });
    }
    // Borrar todos los nodos marcados o que no estan en los canvas.
    // activos o no activos
    talkData.nodes.forEach(node => {
      const { flowId, stepId, name } = node;
      if (
        node.changeStatus === 'deleted' ||
        !allNodeCanvas.includes(node.stepId)
      ) {
        goDeleteStep(flowId, stepId, name);
        console.log(`Deleted step: ${node.stepId}`);
      }
    });
  }

  async onClick() {
    const { goFetchSteps, domainId } = this.props;
    this.setState({
      saving: true,
      buttonText: 'Saving...',
    });
    // ---------------------------------------
    // Saving canvas first
    await this.saveCanvas();
    this.openNotificationWithIcon(
      'success',
      'Canvas Saved',
      'Your canvas was saved successfully',
    );
    // ---------------------------------------
    // Creating and saving steps
    await this.runSaveEngine();
    this.openNotificationWithIcon(
      'success',
      'Steps created and Saved',
      'All your steps was saved successfully',
    );
    // ---------------------------------------
    // Delete remaining deleted steps
    await this.deleteStepBulk();
    this.setState({
      saving: false,
      buttonText: 'Save',
    });
    // Update steps
    setTimeout(() => goFetchSteps(domainId), 2500);
  }

  render() {
    const { saving, buttonText } = this.state;
    return (
      <Button
        ghost
        type="primary"
        icon="save"
        size="default"
        className={styles.save}
        onClick={() => this.onClick()}
        loading={saving}
      >
        {buttonText}
      </Button>
    );
  }
}

const mapStateToProps = state => ({
  canvasState: state.canvas,
  activeCanvasState: state.activeCanvas,
  talkData: state.nodes.talkData,
  domainId: state.bots.selectedBot,
});

const mapDispatchToProps = dispatch => ({
  goPushCanvas: (name, flowId, canvasId, data) =>
    dispatch(pushCanvas(name, flowId, canvasId, data, true)),
  goPushStep: (flowId, stepId, params) =>
    dispatch(pushStep(flowId, stepId, params)),
  goDeleteStep: (flowId, stepId, name) =>
    dispatch(deleteStep(flowId, stepId, name)),
  goFetchSteps: flowId => dispatch(fetchSteps(flowId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropsAPI(Component));
