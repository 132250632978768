/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { Card } from 'antd';

import * as editorActions from '../../actions/editor';

import styles from './index.less';

const Auth = props => {
  // eslint-disable-next-line react/prop-types
  const { goIsSignedIn } = props;
  const [isSignedIn, setSignedIn] = useState(false);
  const [userName, setUserName] = useState(0);
  let auth2;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const letUsergoIn = () => {
    if (auth2.isSignedIn.get()) {
      const userData = auth2.currentUser.get();
      const userProfile = userData.getBasicProfile();
      const email = userProfile.getEmail();
      const name = userProfile.getName();

      if (email.indexOf('@rationaltouch.com') !== -1) {
        setSignedIn(auth2.isSignedIn.get());
        setUserName(name);
        goIsSignedIn(true, 'userData.tc.access_token');
        if (query.get('client') && query.get('canvas')) {
          const path = `/?client=${query.get('client')}&canvas=${query.get(
            'canvas',
          )}`;
          props.history.push(path);
        } else props.history.push('/');
      }
    }
  };

  useEffect(() => {
    window.gapi.load('auth2', () => {
      auth2 = gapi.auth2.init({
        client_id:
          '134219754554-s8dqg7jvh22vre75aktdl0804odn6t5d.apps.googleusercontent.com',
      });

      auth2.then(() => {
        letUsergoIn();
      });

      auth2.isSignedIn.listen(() => {
        letUsergoIn();
      });
    });

    window.gapi.load('signin2', () => {
      // render a sign in button
      // using this method will show Signed In if the user is already signed in
      const opts = {
        width: 200,
        height: 50,
        onSuccess: data => {
          console.log(data);
        },
      };
      gapi.signin2.render('loginButton', opts);
    });
  });

  const getContent = () => {
    if (isSignedIn) {
      return <p>hello {userName}, you&apos;re signed in</p>;
    }
    return (
      <div className={styles.Auth}>
        <Card
          title="RationalTouch Talk Visual Editor"
          bordered={false}
          style={{ width: 350 }}
        >
          <p>You are not signed in. Click here to sign in.</p>
          <div id="loginButton">Login with Google</div>
        </Card>
      </div>
    );
  };

  return <div>{getContent()}</div>;
};

const mapDispatchToProps = dispatch => ({
  goIsSignedIn: (value, name) =>
    dispatch(editorActions.isSignedIn(value, name)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(Auth));
