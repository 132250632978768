/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Form,
  Button,
  Col,
  Row,
  Input,
  Divider,
  Checkbox,
  Radio,
  Alert,
  Tabs,
  Icon,
} from 'antd';

const { TextArea } = Input;
const { TabPane } = Tabs;

export default function Edge({
  getFieldDecorator,
  goCloseDrawer,
  hasErrors,
  getFieldsError,
  languages,
  updateFormData,
}) {
  useEffect(() => {
    updateFormData();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Is Alternative path?">
            {getFieldDecorator('alternative', {
              rules: [{ required: false, message: '' }],
            })(
              <Radio.Group initialValue={1}>
                <Radio value={1}>No</Radio>
                <Radio value={2}>Yes</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Alert message="Select with caution" type="info" showIcon />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Name">
            {getFieldDecorator('item', {
              rules: [{ required: true, message: 'Please enter name' }],
            })(<Input placeholder="Please enter name" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Display Name">
            {getFieldDecorator('display_name', {
              rules: [{ required: true, message: 'Please enter display name' }],
            })(
              <Input
                style={{ width: '100%' }}
                placeholder="Please enter display name"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Tabs tabPosition="left">
        {languages.map(i => (
          <TabPane
            tab={
              <span>
                <Icon type="flag" />
                Language: {i}
              </span>
            }
            key={i}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Description">
                  {getFieldDecorator(`description_${i}`, {
                    rules: [{ required: true, message: 'Please enter text' }],
                  })(<TextArea rows={4} placeholder="Please enter text" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Keywords">
                  {getFieldDecorator(`keywords_${i}`, {
                    rules: [{ required: true, message: 'Please enter text' }],
                  })(<TextArea rows={4} placeholder="Please enter text" />)}
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Kill on end?">
            {getFieldDecorator('kill', {
              rules: [{ required: false, message: '' }],
            })(<Checkbox>Active</Checkbox>)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Button type="default" onClick={goCloseDrawer}>
              Close
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
