function hooks(
  state = {
    preCode: '',
    postCode: '',
    fetching: false,
  },
  action,
) {
  switch (action.type) {
    case 'HOOKS_REQUEST':
      return { ...state, fetching: true, preCode: '', postCode: '' };
    case 'HOOKS_UPDATE_PRE':
      return { ...state, preCode: decodeURIComponent(action.code) };
    case 'HOOKS_RECEIVED_PRE':
      return {
        ...state,
        preCode: decodeURIComponent(action.json.code),
        fetching: false,
      };
    case 'HOOKS_UPDATE_POST':
      return { ...state, postCode: decodeURIComponent(action.code) };
    case 'HOOKS_RECEIVED_POST':
      return {
        ...state,
        postCode: decodeURIComponent(action.json.code),
        fetching: false,
      };

    case 'HOOKS_RESET':
      return {
        ...state,
        preCode: '',
        postCode: '',
      };
    default:
      return state;
  }
}

export default hooks;
