// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._18ejpmZAZAAUzwiBKLDGjG {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background: #fff;\n}\n._3mWLn3d4rcWRU9c4_rQ02j {\n  padding: 8px;\n  border: 1px solid #e6e9ed;\n}\n._3aTllAWJ6DcEEpn9Rv1n3M {\n  flex: 1;\n}\n._3yAFRn1nDIqyWNtbI5ncTt,\n._3BR3FeOrfjChTM59SMaIgY {\n  display: flex;\n  flex-direction: column;\n}\n._3yAFRn1nDIqyWNtbI5ncTt {\n  background: #d1d0d0;\n  max-width: 190px;\n}\n._3yAFRn1nDIqyWNtbI5ncTt:first-child {\n  border-right: 1px solid #e6e9ed;\n}\n._3yAFRn1nDIqyWNtbI5ncTt:last-child {\n  border-left: 1px solid #e6e9ed;\n}\n._20p-EO0_QauziZGcmm-Jwb,\n._3K0KNC-JUuRZPLw5dGM8js,\n._3uYMA4B6YI0M0N7_16vmpf,\n.nfhcO-Y43SJqZUMpJcr0A {\n  flex: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"editor": "_18ejpmZAZAAUzwiBKLDGjG",
	"editorHd": "_3mWLn3d4rcWRU9c4_rQ02j",
	"editorBd": "_3aTllAWJ6DcEEpn9Rv1n3M",
	"editorSidebar": "_3yAFRn1nDIqyWNtbI5ncTt",
	"editorContent": "_3BR3FeOrfjChTM59SMaIgY",
	"flow": "_20p-EO0_QauziZGcmm-Jwb",
	"mind": "_3K0KNC-JUuRZPLw5dGM8js",
	"koni": "_3uYMA4B6YI0M0N7_16vmpf",
	"noFlow": "nfhcO-Y43SJqZUMpJcr0A"
};
module.exports = exports;
