// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1LNQhCZWyYy-g_uNQW_EAE {\n  position: absolute;\n  right: 10px;\n}\n.m8-gOcb8-ByODoZCVoZRY.FrNNHxVqYElImxkWK58wa {\n  position: absolute !important;\n}\n", ""]);
// Exports
exports.locals = {
	"save": "_1LNQhCZWyYy-g_uNQW_EAE",
	"ant-btn": "m8-gOcb8-ByODoZCVoZRY",
	"antBtn": "m8-gOcb8-ByODoZCVoZRY",
	"ant-btn-loading": "FrNNHxVqYElImxkWK58wa",
	"antBtnLoading": "FrNNHxVqYElImxkWK58wa"
};
module.exports = exports;
