import uniqby from 'lodash.uniqby';

async function getAllCanvasNodes(canvasList, snapShot) {
  let allCanvasNodes = [];
  canvasList.forEach(canvas => {
    if (canvas.data.editorItems.nodes) {
      canvas.data.editorItems.nodes.forEach(node => {
        allCanvasNodes.push(node);
      });
    }
  });

  snapShot.nodes.forEach(node => {
    allCanvasNodes.push(node);
  });

  allCanvasNodes = uniqby(allCanvasNodes, x => {
    return x.id;
  });

  return { allCanvasNodes };
}

export default getAllCanvasNodes;
