// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1owQI27ZbUWhSdfVj5BY3y {\n  display: none;\n  overflow: hidden;\n  background: #fff;\n  border-radius: 4px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n}\n._1owQI27ZbUWhSdfVj5BY3y ._1hSsS58GD_wkAus_v_kqgA {\n  display: flex;\n  align-items: center;\n  padding: 5px 12px;\n  cursor: pointer;\n  transition: all 0.3s;\n  -webkit-user-select: none;\n          user-select: none;\n}\n._1owQI27ZbUWhSdfVj5BY3y ._1hSsS58GD_wkAus_v_kqgA:hover {\n  background: #e6f7ff;\n}\n._1owQI27ZbUWhSdfVj5BY3y ._1hSsS58GD_wkAus_v_kqgA i {\n  margin-right: 8px;\n}\n._1owQI27ZbUWhSdfVj5BY3y .disable ._1hSsS58GD_wkAus_v_kqgA {\n  color: rgba(0, 0, 0, 0.25);\n  cursor: auto;\n}\n._1owQI27ZbUWhSdfVj5BY3y .disable ._1hSsS58GD_wkAus_v_kqgA:hover {\n  background: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"contextMenu": "_1owQI27ZbUWhSdfVj5BY3y",
	"item": "_1hSsS58GD_wkAus_v_kqgA"
};
module.exports = exports;
