/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState } from 'react';
import { Icon, Input, Tooltip } from 'antd';

// eslint-disable-next-line import/no-mutable-exports
// eslint-disable-next-line react/prop-types
const NewItem = ({ pushCanvas, pushBot, showInput, type }) => {
  // Declara una nueva variable de estado, que llamaremos "count".
  const [, setType] = useState('none');
  const [name, setName] = useState('');

  const handleKeyPress = () => {
    if (type) {
      switch (type) {
        case 'bot':
          setType('none');
          return pushBot(name);
        case 'canvas':
          setType('none');
          return pushCanvas(name);
        default:
          return {};
      }
    }
    return {};
  };

  const renderInput = (
    <Input
      placeholder="Enter a name"
      prefix={
        <Icon
          type={type === 'bot' ? 'robot' : 'border-outer'}
          style={{ color: 'rgba(0,0,0,.25)' }}
        />
      }
      suffix={
        <Tooltip title="Press Enter to save">
          <Icon type="save" style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      }
      onChange={event => setName(event.target.value)}
      onPressEnter={handleKeyPress}
      style={{ width: '100%', marginTop: '10px' }}
    />
  );
  if (showInput) return renderInput;
  return <></>;
};

export default NewItem;
