// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._24B-7mPU7UuIMBVrV8Xla5 {\n  background: #ececec;\n  padding: 30px;\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"Auth": "_24B-7mPU7UuIMBVrV8Xla5",
	"auth": "_24B-7mPU7UuIMBVrV8Xla5"
};
module.exports = exports;
