import findEdgeTalkData from './findEdgeTalkData';
import getStepConnection from './getStepConnection';

export default async function buildStepConnections(
  allEdgeCanvas,
  involvedNodeIds,
  allNodeCanvas,
  talkData,
  stepBody,
) {
  const step = stepBody;

  allEdgeCanvas.forEach(canvasEdge => {
    if (involvedNodeIds.includes(canvasEdge.source)) {
      const { edgeTalkData } = findEdgeTalkData(
        canvasEdge.id,
        talkData.edges,
        allNodeCanvas,
      );
      const { stepConnection } = getStepConnection(edgeTalkData, allNodeCanvas);
      if (edgeTalkData.isAlternative)
        step.params.alternatives.push(stepConnection);
      else step.params.options.push(stepConnection);
    }
  });

  return { step };
}
