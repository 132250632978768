import hasIn from 'lodash.hasin';

// eslint-disable-next-line consistent-return
export default async function selectNodeModule(
  id,
  isEdge,
  wholeItem,
  nodes,
  canvasItemsNodes,
) {
  if (!isEdge) {
    // -----------------------------------------------------------------------
    // First look for step with this id.
    let stepType;
    let itemId;
    let cloned = false;
    // eslint-disable-next-line array-callback-return
    nodes.forEach(node => {
      if (node.stepId === id) {
        // -------------------------------------------------------------------
        // Encontramos el step.
        stepType = node.params.type;
        itemId = node.stepId;
        if (hasIn(node.params, 'action.actionType')) {
          const { actionType } = node.params.action;
          if (actionType === 'start') stepType = 'start';
          if (actionType === 'end') stepType = 'end';
        }
      }
    });
    // -----------------------------------------------------------------------
    // If not found will be cloned item...
    if (!stepType) {
      canvasItemsNodes.forEach(item => {
        if (item.id === id) {
          stepType = 'action';
          cloned = true;
          itemId = item.originalItemId;
        }
      });
    }
    return {
      _isEdge: isEdge,
      itemId,
      stepType,
      cloned,
      source: false,
      target: false,
    };
  }

  return {
    _isEdge: isEdge,
    itemId: wholeItem.id,
    stepType: false,
    cloned: false,
    source: wholeItem.source,
    target: wholeItem.target,
  };
}
