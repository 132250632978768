export default function editor(
  state = {
    editorStatus: 'closed',
    nodeSelectedType: undefined,
    nodeSelectedCloned: false,
    nodeSelectedId: undefined,
    edgeId: undefined,
    isEditingEdge: false,
    parentId: undefined,
    edgeTargetId: undefined,
    settingsDrawerVisible: true, // Default open, select bot first
    needToSave: false,
    showCheckpointSelector: false,
    isSignedIn: false,
    userName: '',
  },
  action,
) {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return { ...state, editorStatus: 'open' };
    case 'CLOSE_DRAWER':
      return { ...state, editorStatus: 'closed' };
    case 'SELECTED_NODE':
      return {
        ...state,
        nodeSelectedType: action.talkType,
        nodeSelectedId: action.id,
        nodeSelectedCloned: action.cloned,
      };
    case 'SELECTED_EDGE':
      return {
        ...state,
        nodeSelectedId: action.edgeId,
        nodeSelectedType: 'edge',
        nodeSelectedCloned: false,
        edgeId: action.edgeId,
        parentId: action.parentId,
        edgeTargetId: action.targetId,
      };
    case 'CLOSE_SETTINGS_DRAWER':
      return {
        ...state,
        settingsDrawerVisible: !state.settingsDrawerVisible,
      };
    case 'SAVE_CANVAS_REQUEST':
      return {
        ...state,
        needToSave: true,
      };

    case 'SAVE_CANVAS_DONE':
      return {
        ...state,
        needToSave: false,
      };
    case 'SHOW_CHECKPOINT_SELECTOR':
      return {
        ...state,
        showCheckpointSelector: action.show,
      };
    case 'IS_SIGNED_IN':
      return {
        ...state,
        isSignedIn: action.value,
        userName: action.name,
      };
    default:
      return state;
  }
}
