/* eslint-disable no-undef */
import { notification } from 'antd';
import { API_SYSTEM_ENGINE, HEADERS } from '../constants';
import transformStepsReceived from '../common/transformStepsReceived';

export function addNodeCheckPoint(
  name,
  flowId,
  editorItem,
  actionType = 'checkpoint',
  version = '1.0',
) {
  return {
    type: 'ADD_NODE',
    data: {
      flowId,
      version,
      stepId: editorItem.id,
      params: {
        name: name || null,
        type: 'action',
        action: {
          actionType,
          actionParams: {
            finishClientConversation: false,
          },
        },
        options: [],
        alternatives: [],
      },
    },
    editorItem,
  };
}

export function addNodeQuestion(
  name,
  type,
  text,
  entityName,
  entityType,
  flowId,
  editorItem,
  validator,
  version = '1.0',
) {
  return {
    type: 'ADD_NODE',
    data: {
      flowId,
      version,
      stepId: editorItem.id,
      params: {
        name,
        type,
        text,
        entity: {
          name: entityName,
          type: entityType,
        },
        options: [],
        alternatives: [],
        validator,
      },
    },
    editorItem,
  };
}

export function addNodeAction(
  name,
  actionType,
  flowId,
  editorItem,
  type = 'action',
  version = '1.0',
) {
  return {
    type: 'ADD_NODE',
    data: {
      flowId,
      version,
      stepId: editorItem.id,
      params: {
        name,
        type,
        action: {
          actionType,
          actionParams: null,
        },
        options: [],
        alternatives: [],
      },
    },
    editorItem,
  };
}

export function addNodeOutput(
  name,
  type,
  text,
  flowId,
  editorItem,
  repeatLastBotQuestion = false,
  version = '1.0',
) {
  return {
    type: 'ADD_NODE',
    data: {
      flowId,
      version,
      stepId: editorItem.id,
      params: {
        name,
        type,
        text,
        repeatLastBotQuestion,
        options: [],
        alternatives: [],
      },
    },
    editorItem,
  };
}

export function selectNodeCheckPoint(name, flowId, addModel) {
  return {
    type: 'SELECT_NODE_CHECKPOINT',
    name,
    flowId,
    addModel,
  };
}

export function addEdge(edge) {
  return {
    type: 'ADD_EDGE',
    data: {
      source: edge.source,
      target: edge.target,
      id: edge.id,
      talkParams: {},
      isAlternative: false,
      changeStatus: 'updated',
    },
  };
}

export function deleteTalkNode(itemId) {
  return {
    type: 'DELETE_TALK_NODE',
    itemId,
  };
}

export function pushingStep() {
  return {
    type: 'PUSHING_STEP',
  };
}

export function pushStepOK() {
  return {
    type: 'PUSH_STEP_OK',
  };
}

export function pushStepKO() {
  return {
    type: 'PUSH_STEP_KO',
  };
}

export function deleteStepOK() {
  return {
    type: 'DELETE_STEP_OK',
  };
}

export function deleteStepKO() {
  return {
    type: 'DELETE_STEP_KO',
  };
}

export function nodeUpdate(node) {
  return {
    type: 'NODE_UPDATE',
    node,
  };
}

export function edgeUpdate(edge) {
  return {
    type: 'EDGE_UPDATE',
    edge,
  };
}

export function editorItemUpdateEdge(label, id) {
  return {
    type: 'EDITOR_ITEM_UPDATE_EDGE',
    label,
    id,
  };
}

export function stepsReceived(edgeList, steps) {
  return {
    type: 'STEPS_RECEIVED',
    steps,
    edgeList,
  };
}

export function stepFetchError() {
  return {
    type: 'STEPS_FETCH_ERROR',
  };
}

export function fetchSteps(flowId, version = '1.0') {
  return dispatch => {
    return fetch(
      `${API_SYSTEM_ENGINE}/steps?flowId=${flowId}&version=${version}`,
      {
        method: 'GET',
        headers: new Headers(HEADERS),
      },
    )
      .then(response => response.json())
      .then(async json => {
        if (json) {
          // eslint-disable-next-line no-unused-vars
          const edgeList = await transformStepsReceived(json);
          const { steps } = json;
          dispatch(stepsReceived(edgeList, steps));
        } else dispatch(stepFetchError());
      });
  };
}

export function pushStep(flowId, stepId, params, version = '1.0') {
  return dispatch => {
    dispatch(pushingStep());
    return fetch(`${API_SYSTEM_ENGINE}/steps`, {
      method: 'POST',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        flowId,
        version,
        stepId,
        params,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          dispatch(pushStepOK());
        } else {
          dispatch(pushStepKO());
          notification.error({
            message: 'Error pushing step',
            description: `Error pushing ${stepId}`,
          });
        }
      });
  };
}

export function deleteStep(flowId, stepId, name, version = '1.0') {
  return dispatch => {
    return fetch(`${API_SYSTEM_ENGINE}/steps`, {
      method: 'DELETE',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        flowId,
        version,
        stepId,
        params: {
          name: name || '',
        },
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status) dispatch(deleteStepOK());
        else {
          dispatch(deleteStepKO());
          notification.error({
            message: 'Error deleting step',
            description: `Error deleting ${stepId}`,
          });
        }
      });
  };
}
