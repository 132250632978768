/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Select, Card, Skeleton } from 'antd';
import { pushBot } from '../../../../actions/bots';

const { Option } = Select;

function LaguagesSelection({ languages, botList, selectedBot, goPushBot }) {
  // Declare a new state variable, which we'll call "count"

  const children = [
    <Option key="en">
      <span role="img" aria-label="en">
        🇺🇸
      </span>
    </Option>,
    <Option key="es">
      <span role="img" aria-label="es">
        🇪🇸
      </span>
    </Option>,
  ];

  function handleChange(value) {
    if (selectedBot) {
      const currentBot = botList.map(flow => flow.id === selectedBot);
      goPushBot(currentBot.name, selectedBot, value);
    }
  }
  const defaultLanguages = languages;

  const selectah = (
    <Card title="Languages" bordered style={{ width: 400, marginTop: 10 }}>
      <Select
        mode="multiple"
        style={{ width: '100%', minWidth: '100px' }}
        placeholder="Flow Language"
        onChange={handleChange}
        defaultValue={defaultLanguages}
        value={defaultLanguages}
      >
        {children}
      </Select>
    </Card>
  );

  if (selectedBot) return selectah;
  return <Skeleton />;
}

const mapStateToProps = state => ({
  languages: state.bots.languages,
  selectedBot: state.bots.selectedBot,
  botList: state.bots.items,
});

const mapDispatchToProps = dispatch => ({
  goPushBot: (botName, botId, languages) =>
    dispatch(pushBot(botName, botId, languages, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LaguagesSelection);
