/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import { Form, Tabs, Spin } from 'antd';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-xcode';
import { withPropsAPI } from 'gg-editor';

import updateNode from '../updateNode';
import updateFromNode from '../updateFromNode';
import updateEdge from '../updateEdge';
import updateFromEdge from '../updateFromEdge';

import { nodeUpdate, edgeUpdate } from '../../../actions/nodes';

import { closeDrawer } from '../../../actions/editor';

import {
  fetchHooks,
  pushHook,
  hookUpdatePre,
  hookUpdatePost,
  hooksReset,
} from '../../../actions/hooks';

const { TabPane } = Tabs;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeSelectedId: props.nodeSelectedId,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { nodeSelectedId } = this.state;
    if (newProps.nodeSelectedId !== nodeSelectedId) {
      this.setState({ nodeSelectedId: newProps.nodeSelectedId });
      setTimeout(() => this.updateFormData(), 100);
    }
  }

  async updateFormData() {
    const { nodeSelectedType, goHooksReset, goFetchHooks } = this.props;
    let currentTalkNode;
    if (nodeSelectedType !== 'edge') {
      currentTalkNode = await updateFromNode(this.props);
    } else {
      currentTalkNode = await updateFromEdge(this.props);
    }
    // Reset hooks
    goHooksReset();
    goFetchHooks(currentTalkNode.flowId, currentTalkNode.stepId, 'pre');
    goFetchHooks(currentTalkNode.flowId, currentTalkNode.stepId, 'post');
  }

  handleSubmit(e) {
    e.preventDefault();
    const { form, nodeSelectedType } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        if (nodeSelectedType === 'edge') updateEdge(values, this.props);
        else updateNode(values, this.props);
      }
    });
  }

  childrenWithProps() {
    const {
      children,
      goCloseDrawer,
      form,
      nodeSelectedId,
      languages,
      cloned,
      propsAPI,
      talkData,
      editorItems,
      goEdgeUpdate,
    } = this.props;
    const { getFieldDecorator, getFieldsError } = form;
    return React.Children.map(children, child =>
      React.cloneElement(child, {
        getFieldDecorator,
        getFieldsError,
        goCloseDrawer,
        hasErrors,
        languages,
        cloned,
        updateFormData: this.updateFormData.bind(this),
        nodeSelectedId,
        propsAPI,
        talkData,
        editorItems,
        goEdgeUpdate,
      }),
    );
  }

  preCodeHooksRenderer() {
    const { preCode, hooksFetching, goHookUpdatePre } = this.props;

    if (hooksFetching) {
      return <Spin />;
    }

    return (
      <AceEditor
        style={{ width: '100%' }}
        placeholder="code here"
        mode="python"
        theme="xcode"
        name="precode"
        // onLoad={this.onLoad}
        onChange={code => goHookUpdatePre(code)}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        value={preCode}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    );
  }

  postCodeHooksRenderer() {
    const { postCode, hooksFetching, goHookUpdatePost } = this.props;

    if (hooksFetching) {
      return <Spin />;
    }

    return (
      <AceEditor
        style={{ width: '100%' }}
        placeholder="code here"
        mode="python"
        theme="xcode"
        name="precode"
        // onLoad={this.onLoad}
        onChange={code => goHookUpdatePost(code)}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        value={postCode}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    );
  }

  render() {
    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab="Values" key="1">
          <Form
            layout="vertical"
            onSubmit={e => this.handleSubmit(e)}
            hideRequiredMark
          >
            {this.childrenWithProps()}
          </Form>
        </TabPane>
        <TabPane tab="Pre-Code" key="2">
          {this.preCodeHooksRenderer()}
        </TabPane>
        <TabPane tab="Post-Code" key="3">
          {this.postCodeHooksRenderer()}
        </TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = state => ({
  talkData: state.nodes.talkData,
  editorItems: {
    nodes: state.activeCanvas.nodes,
    edges: state.activeCanvas.edges,
  },
  nodeSelectedId: state.editor.nodeSelectedId,
  selectedCanvas: state.activeCanvas.selectedCanvas,
  canvasList: state.canvas.items,
  editorStatus: state.editor.editorStatus,
  preCode: state.hooks.preCode,
  postCode: state.hooks.postCode,
  nodeSelectedType: state.editor.nodeSelectedType,
  edgeId: state.editor.edgeId,
  parentId: state.editor.parentId,
  edgeTargetId: state.editor.edgeTargetId,
  languages: state.bots.languages,
  cloned: state.editor.nodeSelectedCloned,
  hooksFetching: state.hooks.fetching,
});

const mapDispatchToProps = dispatch => ({
  goNodeUpdate: node => dispatch(nodeUpdate(node)),
  goEdgeUpdate: edge => dispatch(edgeUpdate(edge)),
  goCloseDrawer: () => dispatch(closeDrawer()),
  goFetchHooks: (flowId, stepId, phase) =>
    dispatch(fetchHooks(flowId, stepId, phase)),
  goPushHook: (flowId, stepId, phase, code) =>
    dispatch(pushHook(flowId, stepId, phase, code)),
  goHookUpdatePre: code => dispatch(hookUpdatePre(code)),
  goHookUpdatePost: code => dispatch(hookUpdatePost(code)),
  goHooksReset: () => dispatch(hooksReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(withPropsAPI(Base)));
