/* eslint-disable no-undef */
import { API_SYSTEM_ENGINE, HEADERS } from '../constants';

export function hooksRequest(flowId, stepId, phase, version = '1.0') {
  return {
    type: 'HOOKS_REQUEST',
    flowId,
    stepId,
    phase,
    version,
  };
}

export function hooksReceivedPre(json) {
  return {
    type: 'HOOKS_RECEIVED_PRE',
    json,
  };
}

export function hooksReceivedPost(json) {
  return {
    type: 'HOOKS_RECEIVED_POST',
    json,
  };
}

export const HOOKS_UPDATE_PRE = 'HOOKS_UPDATE_PRE';
export function hookUpdatePre(code) {
  return {
    type: HOOKS_UPDATE_PRE,
    code: encodeURIComponent(code),
  };
}

export const HOOKS_UPDATE_POST = 'HOOKS_UPDATE_POST';
export function hookUpdatePost(code) {
  return {
    type: HOOKS_UPDATE_POST,
    code: encodeURIComponent(code),
  };
}

export const HOOKS_ADD = 'HOOKS_ADD';
export function hooksAdd(flowId, stepId, phase, code, version = '1.0') {
  return {
    type: HOOKS_ADD,
    flowId,
    stepId,
    phase,
    code,
    version,
  };
}

export const HOOKS_DELETED = 'HOOKS_DELETED';
export function hooksDeleted(phase) {
  return {
    type: HOOKS_DELETED,
    phase,
  };
}

export function hooksReset() {
  return {
    type: 'HOOKS_RESET',
  };
}

export function fetchHooks(flowId, stepId, phase, version = '1.0') {
  return dispatch => {
    dispatch(hooksRequest(flowId, stepId, phase, version));
    return fetch(
      `
    ${API_SYSTEM_ENGINE}/webhooks?flowId=${flowId}&version=${version}&stepId=${stepId}&phase=${phase}`,
      {
        method: 'GET',
        headers: new Headers(HEADERS),
      },
    )
      .then(response => response.json())
      .then(json => {
        if (phase === 'pre') return dispatch(hooksReceivedPre(json.webhook));
        return dispatch(hooksReceivedPost(json.webhook));
      })
      .catch(() => {
        if (phase === 'pre')
          return dispatch(
            hooksReceivedPre({
              code: '',
            }),
          );
        return dispatch(
          hooksReceivedPost({
            code: '',
          }),
        );
      });
  };
}

export function pushHook(flowId, stepId, phase, code, version = '1.0') {
  // eslint-disable-next-line no-unused-vars
  return dispatch => {
    return fetch(`${API_SYSTEM_ENGINE}/webhooks`, {
      method: 'POST',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        flowId,
        stepId,
        version,
        phase,
        code: code === '' ? 'None' : encodeURIComponent(code),
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status) console.log('Push webhook ok');
        else console.log('Push webhooks error');
      });
  };
}

export function deleteHook(
  flowId,
  stepId,
  phase,
  version = '1.0',
  code = 'None',
) {
  return dispatch => {
    return fetch(`${API_SYSTEM_ENGINE}/webhooks`, {
      method: 'POST',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        flowId,
        stepId,
        version,
        phase,
        code,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status) dispatch(hooksDeleted(phase));
        else console.log('Push webhooks error');
      });
  };
}
