import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import Root from './components/Root';
import rootReducers from './reducers';
import { fetchBotsIfNeeded } from './actions/bots';

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);

/**
 * Load initial data from server
 */
store.dispatch(fetchBotsIfNeeded());

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
