export default function ifCheckpoint(id, talkDataNodes) {
  let isCheckpoint = false;
  talkDataNodes.forEach(element => {
    if (element.stepId === id) {
      if (
        element.params.type === 'action' &&
        element.params.action.actionType === 'checkpoint'
      ) {
        isCheckpoint = true;
      }
    }
  });

  return isCheckpoint;
}
