/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
function nodes(
  state = {
    talkData: {
      nodes: [],
      edges: [],
    },
    isPushing: false,
    isDeleting: false,
    msgOK: false,
    msgKO: false,
    msgText: false,
    tmpCPname: '',
    tmpCPflowId: '',
    tmpCPaddModel: {},
  },
  action,
) {
  switch (action.type) {
    case 'ADD_NODE':
      return {
        ...state,
        talkData: {
          nodes: [
            ...state.talkData.nodes,
            {
              ...action.data,
              changeStatus: 'updated',
            },
          ],
          edges: [...state.talkData.edges],
        },
      };
    case 'ADD_EDGE':
      return {
        ...state,
        talkData: {
          nodes: [...state.talkData.nodes],
          edges: [...state.talkData.edges, action.data],
        },
      };
    case 'SELECT_NODE_CHECKPOINT':
      return {
        ...state,
        tmpCPname: action.name,
        tmpCPflowId: action.flowId,
        tmpCPaddModel: action.addModel,
      };
    case 'DELETE_TALK_NODE':
      return {
        ...state,
        talkData: {
          nodes: [
            ...state.talkData.nodes.map(item => {
              if (item.stepId === action.itemId) item.changeStatus = 'deleted';
              return item;
            }),
          ],
          edges: [...state.talkData.edges],
        },
      };
    case 'STEPS_RECEIVED':
      return {
        ...state,
        talkData: {
          nodes: [
            ...action.steps.map(item => {
              // eslint-disable-next-line prefer-object-spread
              const newNode = JSON.parse(JSON.stringify(item));
              newNode.changeStatus = 'not_changed'; // not_changed, updated, deleted
              delete newNode.params.options;
              delete newNode.params.alternatives;
              return newNode;
            }),
          ],
          edges: [
            // eslint-disable-next-line array-callback-return
            ...action.edgeList,
          ],
        },
      };
    case 'NODE_UPDATE':
      return {
        ...state,
        talkData: {
          nodes: [
            ...state.talkData.nodes.filter(
              item => item.stepId !== action.node.stepId,
            ),
            {
              ...action.node,
              changeStatus: 'updated',
            },
          ],
          edges: [...state.talkData.edges],
        },
      };
    case 'EDGE_UPDATE':
      return {
        ...state,
        talkData: {
          nodes: [...state.talkData.nodes],
          edges: [
            ...state.talkData.edges.filter(item => item.id !== action.edge.id),
            {
              ...action.edge,
              changeStatus: 'updated',
            },
          ],
        },
      };
    case 'PUSHING_STEP':
      return {
        ...state,
        isPushing: true,
      };
    case 'PUSH_STEP_OK':
      return {
        ...state,
        isPushing: false,
      };
    case 'PUSHING_STEP_KO':
      return {
        ...state,
        isPushing: false,
        msgKO: true,
        msgText: 'Error pushing step... :(',
      };

    default:
      return state;
  }
}

export default nodes;
