/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';

import { closeDrawer } from '../../actions/editor';
import Base from './components/Base';
import Action from './components/Action';
import Start from './components/Start';
import Question from './components/Question';
import Message from './components/Message';
import Clarification from './components/Clarification';
import Edge from './components/Edge';
import End from './components/End';

const toRender = nodeSelectedType => {
  switch (nodeSelectedType) {
    case 'start':
      return (
        <Base>
          <Start />
        </Base>
      );
    case 'webhook':
    case 'action':
      return (
        <Base>
          <Action />
        </Base>
      );
    case 'end':
      return (
        <Base>
          <End />
        </Base>
      );
    case 'closed':
    case 'open':
      return (
        <Base>
          <Question />
        </Base>
      );
    case 'message':
      return (
        <Base>
          <Message />
        </Base>
      );
    case 'clarification':
      return (
        <Base>
          <Clarification />
        </Base>
      );
    case 'edge':
      return (
        <Base>
          <Edge />
        </Base>
      );
    default:
      return <Base />;
  }
};

function ItemOpts({ status, goCloseDrawer, nodeSelectedType }) {
  const visible = status !== 'closed';

  const onClose = () => {
    goCloseDrawer();
  };

  return (
    <Drawer
      title="Step Edit"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={720}
      destroyOnClose
    >
      {toRender(nodeSelectedType)}
    </Drawer>
  );
}

const mapStateToProps = state => ({
  status: state.editor.editorStatus,
  nodeSelectedType: state.editor.nodeSelectedType,
});

const mapDispatchToProps = dispatch => ({
  goCloseDrawer: () => dispatch(closeDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemOpts);
