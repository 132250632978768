/* eslint-disable no-undef */
import * as hat from 'hat';
import { API_SYSTEM_ENGINE, HEADERS } from '../constants';
import { fetchSteps } from './nodes';
import { loadCanvasItems } from './activeCanvas';

export function addCanvas(
  name,
  botId,
  canvasId,
  data = {
    editorItems: {
      nodes: [],
      edges: [],
    },
  },
) {
  return {
    type: 'ADD_CANVAS',
    name,
    canvasId,
    botId,
    data,
  };
}

export function requestCanvas() {
  return {
    type: 'REQUEST_CANVAS',
  };
}

export function savedBot(json) {
  return {
    type: 'SAVED_CANVAS',
    bot: json,
  };
}

export function pushingCanvasError(name, botId, canvasId) {
  return {
    type: 'PUSHING_CANVAS_KO',
    name,
    canvasId,
    botId,
  };
}

export function deletedCanvas(canvasId) {
  return {
    type: 'DELETED_CANVAS',
    canvasId,
  };
}

export function deleteCanvasError(canvasId) {
  return {
    type: 'DELETED_CANVAS_ERROR',
    canvasId,
  };
}

export function isDeleting() {
  return {
    type: 'IS_DELETING_CANVAS',
  };
}

export function updateCanvasNodeData(editorItems, steps, canvasId) {
  return {
    type: 'UPDATE_CANVAS_NODE_DATA',
    editorItems,
    steps,
    canvasId,
  };
}

export function receiveCanvas(json, botId) {
  return dispatch => {
    dispatch({
      type: 'RECEIVE_CANVAS',
      canvas: json.canvas,
      botId,
    });
  };
}

export function fetchCanvas(flowId, version = '1.0') {
  return dispatch => {
    dispatch(requestCanvas);
    return fetch(
      `${API_SYSTEM_ENGINE}/canvas?flowId=${flowId}&version=${version}`,
      {
        method: 'GET',
        headers: new Headers(HEADERS),
      },
    )
      .then(response => response.json())
      .then(json => {
        dispatch(receiveCanvas(json, flowId));
        dispatch(fetchSteps(flowId));
      });
  };
}

const defaultData = {
  editorItems: {
    nodes: [],
    edges: [],
  },
};
export function pushCanvas(
  name,
  flowId,
  canvasId = hat(),
  data = defaultData,
  update = false,
  version = '1.0',
) {
  return dispatch => {
    return fetch(`${API_SYSTEM_ENGINE}/canvas`, {
      method: 'POST',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        name,
        flowId,
        canvasId,
        version,
        data,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status && !update) {
          dispatch({
            type: 'PUSHING_CANVAS_OK',
          });
          dispatch(addCanvas(name, flowId, canvasId));
          dispatch(loadCanvasItems(canvasId, undefined, name));
          dispatch(fetchCanvas(flowId));
        } else if (result.status && update) {
          dispatch({
            type: 'PUSHING_CANVAS_OK',
          });
          dispatch(fetchCanvas(flowId));
        }
        if (!result.status)
          dispatch(pushingCanvasError(name, flowId, canvasId));
      });
  };
}

export function deleteCanvas(canvasId, flowId, version = '1.0') {
  return dispatch => {
    dispatch(isDeleting());
    return fetch(`${API_SYSTEM_ENGINE}/canvas`, {
      method: 'DELETE',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        canvasId,
        flowId,
        version,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          dispatch(loadCanvasItems());
          dispatch(deletedCanvas(canvasId));
        } else dispatch(deleteCanvasError(canvasId));
      });
  };
}
