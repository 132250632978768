/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import pick from 'lodash.pick';

export default function updateNode(values, props) {
  let step;
  const {
    talkData,
    nodeSelectedId,
    nodeSelectedType,
    goNodeUpdate,
    goPushHook,
    preCode,
    postCode,
    goCloseDrawer,
    languages,
    propsAPI,
  } = props;

  const langProps = languages.map(lang => {
    return `${lang}`;
  });
  const langValues = pick(values, langProps);
  langProps.forEach(lang => delete values[lang]);
  values.text = langValues;

  const canvasItem = propsAPI.find(nodeSelectedId);

  talkData.nodes.forEach(element => {
    if (element.stepId === nodeSelectedId) step = element;
  });

  if (nodeSelectedType === 'start') {
    if (!step.params.action.actionParams) step.params.action.actionParams = {};
    step.params.action.actionParams.intent = values.intent_name;

    step.params = {
      name: 'start',
      options: step.params.options,
      alternatives: step.params.alternatives || [],
      action: step.params.action || null,
    };
  } else if (nodeSelectedType === 'end') {
    if (!step.params.action.actionParams) step.params.action.actionParams = {};
    step.params.action.actionParams.finishClientConversation =
      values.client_finish_conversation;
    step.params = {
      name: 'end',
      options: step.params.options,
      alternatives: step.params.alternatives,
      action: step.params.action,
    };
  } else {
    values.entity = {
      name: values.entity_name || null,
      type: values.entity_type || null,
    };
    delete values.entity_name;
    delete values.entity_type;

    step.params = {
      ...values,
      options: step.params.options,
      alternatives: step.params.alternatives || [],
      action: step.params.action || null,
    };
  }

  step.params.type =
    nodeSelectedType === 'start' || nodeSelectedType === 'end'
      ? 'action'
      : nodeSelectedType;

  delete step.params.display_name;
  // --------------------------
  // Update canvas display name
  canvasItem.model.label = values.display_name;
  propsAPI.update(canvasItem, canvasItem.model);

  goNodeUpdate(step);
  goPushHook(step.flowId, step.stepId, 'pre', preCode);
  goPushHook(step.flowId, step.stepId, 'post', postCode);
  goCloseDrawer();
}
