/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Select, Icon, Row, Col, Card, Button, Skeleton, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  selectBot,
  pushBot,
  deleteBot,
  selectedLanguages,
} from '../../actions/bots';

import {
  addCanvas,
  pushCanvas,
  fetchCanvas,
  deleteCanvas,
} from '../../actions/canvas';

import { closeSettingsDrawer } from '../../actions/editor';

import { loadCanvasItems } from '../../actions/activeCanvas';
import { deleteStep } from '../../actions/nodes';
import { pushHook } from '../../actions/hooks';
import NewItem from './components/NewItem';
import LanguageSelection from './components/LanguageSelection';

const { Option } = Select;
const { confirm } = Modal;

function Explorer({
  botList,
  canvasList,
  changeSelectedBot,
  changeSelectedCanvas,
  goPushBot,
  goPushCanvas,
  selectedBot,
  getCanvasFromBot,
  selectedCanvas,
  goDeleteBot,
  goDeleteCanvas,
  goSelectedLanguages,
  goDeleteStep,
  activeCanvasNodes,
  goPushHook,
  isFetchingBots,
  goCloseSettingsDrawer,
}) {
  // eslint-disable-next-line no-unused-vars
  const [valueShowNewBot, setValueShowNewBot] = useState(false);
  const [valueShowNewCanvas, setValueNewCanvas] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const onChange = (botName, canvasName, closeSettings) => {
    botList.map(bot => {
      if (bot.flowId === botName) {
        changeSelectedCanvas();
        getCanvasFromBot(botName);
        changeSelectedBot(botName);
        return goSelectedLanguages(bot.languages);
      }
      return {};
    });

    if (!canvasName) return;

    canvasList.map(canvas => {
      if (canvas.canvasId === canvasName) {
        getCanvasFromBot(selectedBot, canvasName);
        changeSelectedCanvas(
          canvas.canvasId,
          canvas.data.editorItems,
          canvasName,
        );
      }
      return {};
    });

    if (closeSettings) goCloseSettingsDrawer();
  };

  useEffect(() => {
    if (query.get('client') && query.get('canvas')) {
      onChange(query.get('client').replace('-', '/'), query.get('canvas'));
    }
  }, []);

  useEffect(() => {
    if (query.get('canvas') && canvasList.length > 0) {
      onChange(undefined, query.get('canvas'), true);
    }
  }, [canvasList.length]);

  const createCanvas = name => {
    goPushCanvas(name, selectedBot);
    setValueNewCanvas(false);
  };

  const createBot = name => {
    goPushBot(name);
    setValueShowNewBot(undefined);
    setValueShowNewBot(false);
  };

  const onDeleteBot = () => {
    // eslint-disable-next-line no-new
    new Promise(resolve => {
      // eslint-disable-next-line array-callback-return
      botList.forEach(bot => {
        if (bot.flowId === selectedBot) {
          goDeleteBot(bot.domainName, bot.flowId);
          canvasList.map(item => goDeleteCanvas(item.canvasId, selectedBot));
        }
      });

      resolve(true);
    });
  };

  const onDeleteCanvas = () => {
    // TODO meter aqui que busque si hay nodos con conexiones a los steps que borramos!!!!
    // -------------------------------------------------------------------------
    // Delete only steps for this canvas.
    // eslint-disable-next-line no-new
    new Promise(resolve => {
      canvasList.forEach(canvas => {
        if (canvas.canvasId === selectedCanvas) {
          goDeleteCanvas(canvas.canvasId, selectedBot);
          activeCanvasNodes.forEach(node => {
            goDeleteStep(selectedBot, node.id, node.label);
            goPushHook(selectedBot, node.id, 'pre', '');
            goPushHook(selectedBot, node.id, 'post', '');
            changeSelectedCanvas();
          });
        }
      });
      resolve(true);
    });
  };

  const showConfirm = type => {
    confirm({
      title: `Do you want to delete these ${type}?`,
      content: `This ${type} will be permanently deleted and cannot be retrieved.`,
      onOk() {
        if (type === 'canvas') onDeleteCanvas();
        if (type === 'bot') onDeleteBot();
      },
      onCancel() {},
    });
  };

  const createCanvasList = () => {
    if (selectedBot !== undefined) {
      const children = [];
      for (let index = 0; index < canvasList.length; index += 1) {
        const item = canvasList[index];
        children.push(
          <Option key={item.canvasId} value={item.canvasId}>
            <Icon type="border-outer" /> {item.name}
          </Option>,
        );
      }

      return (
        <Card
          title="Canvas Selection"
          bordered
          style={{ width: 400, marginTop: 10 }}
        >
          <Select
            style={{ width: 260 }}
            placeholder="Select canvas please"
            onChange={value => onChange(undefined, value)}
            value={selectedCanvas}
          >
            {children}
          </Select>
          <Button
            style={{ marginLeft: '10px' }}
            type="default"
            shape="circle"
            icon="plus"
            size="small"
            onClick={() => setValueNewCanvas(!valueShowNewCanvas)}
          />
          <Button
            style={{ marginLeft: '10px' }}
            type="danger"
            shape="circle"
            icon="delete"
            size="small"
            onClick={() => showConfirm('canvas')}
          />
          <NewItem
            addCanvas={addCanvas}
            pushBot={createBot}
            pushCanvas={createCanvas}
            selectedBot={selectedBot}
            showInput={valueShowNewCanvas}
            type="canvas"
          />
        </Card>
      );
    }
    return <Skeleton />;
  };

  return (
    <div style={{ background: '#ECECEC', padding: '30px' }}>
      <Row type="flex">
        <Col span={8}>
          <Card
            title="Domain Selection"
            bordered={false}
            style={{ width: 400 }}
          >
            <Select
              style={{ width: 260 }}
              placeholder="Select Domain please"
              onChange={value => onChange(value, undefined)}
              value={selectedBot}
              loading={isFetchingBots}
              optionFilterProp="children"
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {botList
                .filter(b => !b.domainName.includes('_voicemail'))
                .map(item => (
                  <Option key={item.flowId} value={item.flowId}>
                    <Icon type="robot" /> {item.domainName}
                  </Option>
                ))}
            </Select>
            <Button
              style={{ marginLeft: '10px' }}
              type="default"
              shape="circle"
              icon="plus"
              size="small"
              onClick={() => setValueShowNewBot(!valueShowNewBot)}
            />
            <Button
              style={{ marginLeft: '10px' }}
              type="danger"
              shape="circle"
              icon="delete"
              size="small"
              onClick={() => showConfirm('bot')}
            />
            <NewItem
              addCanvas={addCanvas}
              pushBot={goPushBot}
              pushCanvas={createCanvas}
              selectedBot={selectedBot}
              showInput={valueShowNewBot}
              type="bot"
            />
          </Card>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={8}>{createCanvasList()}</Col>
      </Row>
      <Row type="flex">
        <Col span={8}>
          <LanguageSelection />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = state => ({
  botList: state.bots.items,
  isFetchingBots: state.bots.isFetching,
  selectedBot: state.bots.selectedBot,
  canvasList: state.canvas.items,
  selectedCanvas: state.activeCanvas.selectedCanvas,
  activeCanvasNodes: state.activeCanvas.nodes || [],
  canvasIsFetching: state.canvas.isFetching,
  stepList: state.nodes.steps,
});

const mapDispatchToProps = dispatch => ({
  changeSelectedBot: id => dispatch(selectBot(id)),
  changeSelectedCanvas: (id, editorItems, name) =>
    dispatch(loadCanvasItems(id, editorItems, name)),
  goPushBot: name => dispatch(pushBot(name)),
  goPushCanvas: (name, selectedBot) => dispatch(pushCanvas(name, selectedBot)),
  getCanvasFromBot: botId => dispatch(fetchCanvas(botId)),
  goDeleteBot: (domainName, flowId) => dispatch(deleteBot(domainName, flowId)),
  goDeleteCanvas: (canvasId, flowId) =>
    dispatch(deleteCanvas(canvasId, flowId)),
  goSelectedLanguages: languages => dispatch(selectedLanguages(languages)),
  goDeleteStep: (flowId, stepId, name) =>
    dispatch(deleteStep(flowId, stepId, name)),
  goPushHook: (flowId, stepId, phase, code) =>
    dispatch(pushHook(flowId, stepId, phase, code)),
  goCloseSettingsDrawer: () => dispatch(closeSettingsDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Explorer);
