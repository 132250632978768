/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Form, Button, Col, Row, Input, Divider } from 'antd';

export default function Message({
  getFieldDecorator,
  goCloseDrawer,
  hasErrors,
  getFieldsError,
  languages,
  updateFormData,
}) {
  useEffect(() => {
    updateFormData();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please enter name' }],
            })(<Input placeholder="Please enter name" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Display Name">
            {getFieldDecorator('display_name', {
              rules: [{ required: true, message: 'Please enter display name' }],
            })(
              <Input
                style={{ width: '100%' }}
                placeholder="Please enter display name"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="right">Text by language</Divider>
      {languages.map(lang => {
        return (
          <Row gutter={16} key={lang}>
            <Col span={24}>
              <Form.Item label={`${lang}`}>
                {getFieldDecorator(`${lang}`, {
                  rules: [{ required: true, message: 'Please enter text' }],
                })(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Please enter text"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
        );
      })}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Button type="default" onClick={goCloseDrawer}>
              Close
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
