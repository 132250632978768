/* eslint-disable no-undef */
import * as hat from 'hat';
// import 'cross-fetch/polyfill';
import { API_SYSTEM_ENGINE, HEADERS } from '../constants';

export function addBot(domainName, flowId, languages) {
  return {
    type: 'ADD_BOT',
    domainName,
    flowId,
    languages,
  };
}

export function requestBots() {
  return {
    type: 'REQUEST_BOTS',
  };
}

export function receiveBots(json) {
  return {
    type: 'RECEIVE_BOTS',
    bots: json.flows,
  };
}

export function selectBot(flowId) {
  return {
    type: 'SELECT_BOT',
    flowId,
  };
}

export function savedBot(json) {
  return {
    type: 'SAVED_BOT',
    bot: json,
  };
}

export function pushinBotError(botName, botId) {
  return {
    type: 'PUSHING_BOT_ERROR',
    botName,
    botId,
  };
}

export function deletedBot(flowId) {
  return {
    type: 'DELETED_BOT',
    flowId,
  };
}

export function deleteBotError(flowId) {
  return {
    type: 'DELETED_BOT_ERROR',
    flowId,
  };
}

export function isDeleting() {
  return {
    type: 'IS_DELETING',
  };
}

export function selectedLanguages(languages) {
  return {
    type: 'SELECTED_LANGUAGES',
    languages,
  };
}

function fetchBots() {
  return dispatch => {
    dispatch(requestBots());
    return fetch(`${API_SYSTEM_ENGINE}/flows`, {
      method: 'GET',
      headers: new Headers(HEADERS),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveBots(json)));
  };
}

function shouldFetchBots(state) {
  const bots = state.bots.items;
  if (!bots) {
    return true;
  }

  if (bots.isFetching) {
    return false;
  }

  return true;
}

export function fetchBotsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchBots(getState())) {
      return dispatch(fetchBots());
    }
    return Promise.resolve();
  };
}

export function pushBot(
  domainName,
  flowId = hat(),
  languages = ['es'],
  update = false,
  version = '1.0',
  stage = 'published',
) {
  return dispatch => {
    return fetch(`${API_SYSTEM_ENGINE}/flows`, {
      method: 'POST',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        domainName,
        flowId,
        version,
        stage,
        languages,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status && !update)
          dispatch(addBot(domainName, flowId, languages));
        else if (result.status && update) {
          dispatch(selectedLanguages(languages, flowId));
          dispatch(fetchBots());
        } else dispatch(pushinBotError(domainName, flowId));
      });
  };
}

export function deleteBot(botName, flowId, version = '1.0') {
  return dispatch => {
    dispatch(isDeleting());
    return fetch(`${API_SYSTEM_ENGINE}/flows`, {
      method: 'DELETE',
      headers: new Headers(HEADERS),
      body: JSON.stringify({
        botName,
        flowId,
        version,
      }),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          dispatch(deletedBot(flowId));
          dispatch(selectBot(undefined));
        } else dispatch(deleteBotError(flowId));
      });
  };
}
