export default function canvas(
  state = {
    isFetching: false,
    isPushing: false,
    isDeleting: false,
    items: [],
    msgOK: false,
    msgKO: false,
    msgText: '',
  },
  action,
) {
  switch (action.type) {
    case 'ADD_CANVAS':
      return {
        ...state,
        items: [
          ...state.items,
          {
            canvasId: action.canvasId,
            data: action.data,
            name: action.name,
            botId: action.botId,
          },
        ],
      };
    case 'PUSING_CANVAS_OK':
      return {
        ...state,
        isPushing: false,
        msgKO: false,
        msgOK: false,
        msgText: '',
      };
    case 'PUSHING_CANVAS_KO':
      return {
        ...state,
        isPushing: false,
        msgText: 'Error pushing canvas... :(',
        msgKO: true,
      };
    case 'REQUEST_CANVAS':
      return { ...state, isFetching: true };
    case 'RECEIVE_CANVAS':
      return {
        ...state,
        isFetching: false,
        msgKO: false,
        msgOK: false,
        msgText: '',
        items: [
          ...action.canvas.map(item => ({ ...item, botId: action.botId })),
        ],
        lastUpdate: action.receivedAt,
      };
    case 'IS_DELETING_CANVAS':
      return { ...state, isDeleting: true };
    case 'DELETED_CANVAS_ERROR':
      return {
        ...state,
        isDeleting: false,
        msgKO: true,
        msgText: 'Error deleting canvas',
      };
    case 'DELETED_CANVAS':
      return {
        ...state,
        items: [
          ...state.items.filter(item => item.canvasId !== action.canvasId),
        ],
        isDeleting: false,
        msgOK: true,
        msgText: 'Canvas Deleted!',
        selectedCanvas: undefined,
      };
    default:
      return state;
  }
}
