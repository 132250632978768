/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { Modal, Select } from 'antd';
import { connect } from 'react-redux';
import uniqby from 'lodash.uniqby';
import { withPropsAPI } from 'gg-editor';

import * as nodesActions from '../../actions/nodes';
import * as activeCanvasActions from '../../actions/activeCanvas';
import * as editorActions from '../../actions/editor';
import getAllCanvasNodes from '../../common/getAllCanvasNodes';

const { Option } = Select;

class CheckpointModal extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPrevCheckpoint: false,
      cleanCheckpointList: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { showCheckpointSelector } = this.props;
    if (prevProps.showCheckpointSelector !== showCheckpointSelector) {
      this.getCheckpoints();
    }
  }

  handleOk = () => {
    const {
      addClonedCheckpoint,
      tmpCPName,
      tmpCPaddModel,
      tmpCPflowId,
      goNewCheckPoint,
      closeModal,
    } = this.props;

    const { cleanCheckpointList } = this.state;

    const { selectedPrevCheckpoint } = this.state;

    if (selectedPrevCheckpoint) {
      cleanCheckpointList.forEach(chp => {
        if (chp.checkpoint.stepId === selectedPrevCheckpoint) {
          // ---------------------------------------------------------------
          // Add cloned checkpoint only to activeCanvas in order to visualization
          // and create connections before.
          addClonedCheckpoint({
            ...tmpCPaddModel,
            originalItemId: selectedPrevCheckpoint,
            label: `${chp.label} - ♻️`,
          });
        }
      });
    } else {
      // New checkpoint to talkData
      goNewCheckPoint(tmpCPName, tmpCPflowId, tmpCPaddModel);
    }

    closeModal();
  };

  // ------------------------------------------------------------
  handleCancel = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  // ------------------------------------------------------------
  onChange = value => {
    this.setState({
      selectedPrevCheckpoint: value,
    });
  };

  // ------------------------------------------------------------
  onBlur = () => {
    // console.log('blur');
  };

  // ------------------------------------------------------------
  onFocus = () => {
    // console.log('focus');
  };

  // ------------------------------------------------------------
  onSearch = val => {
    // eslint-disable-next-line no-console
    console.log('search:', val);
  };

  async getCheckpoints() {
    const { nodes, canvas, propsAPI } = this.props;
    if (!nodes || !canvas) return;
    const checkpointList = [];
    const { allCanvasNodes } = await getAllCanvasNodes(
      canvas.items,
      propsAPI.save(),
    );

    const allCheckpoints = nodes
      .filter(step => step.params.type === 'action')
      .filter(step => step.params.action.actionType === 'checkpoint');

    allCheckpoints.forEach(chp =>
      allCanvasNodes.forEach(canvasNodeItem => {
        if (chp.stepId === canvasNodeItem.id) {
          checkpointList.push({
            checkpoint: chp,
            stepId: chp.stepId,
            label: canvasNodeItem.label,
          });
        }
      }),
    );

    const sortedCheckpointList = uniqby(checkpointList, x => {
      return x.stepId;
    });

    sortedCheckpointList.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    this.setState({
      cleanCheckpointList: sortedCheckpointList,
    });
  }

  // ------------------------------------------------------------
  createCheckpointList = () => {
    const { showCheckpointSelector } = this.props;
    const { cleanCheckpointList } = this.state;

    if (showCheckpointSelector) {
      const children = [];
      cleanCheckpointList.forEach(chp => {
        children.push(
          <Option key={chp.checkpoint.stepId} value={chp.checkpoint.stepId}>
            {`${chp.label}`} <strong>{`(${chp.stepId})`}</strong>
          </Option>,
        );
      });

      return (
        <Select
          style={{ width: 400 }}
          placeholder="Select previous Checkpoint"
          optionFilterProp="children"
          onChange={value => this.onChange(value)}
          onFocus={() => this.onFocus}
          onBlur={() => this.onBlur}
          onSearch={() => this.onSearch}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {children}
        </Select>
      );
    }

    return <></>;
  };

  // ------------------------------------------------------------
  resetSelection = () => {
    this.setState({
      selectedPrevCheckpoint: false,
    });
  };

  // ------------------------------------------------------------
  render() {
    const { showCheckpointSelector } = this.props;
    const { selectedPrevCheckpoint } = this.state;
    const okText = selectedPrevCheckpoint ? 'Clone' : 'New';
    return (
      <Modal
        title="Checkpoint Node Creation"
        visible={showCheckpointSelector}
        onOk={this.handleOk}
        // onCancel={this.handleCancel}
        afterClose={this.resetSelection}
        closable={false}
        okText={okText}
        cancelButtonProps={{ disabled: true }}
      >
        <p>Clone previous Checkpoint?</p>
        {this.createCheckpointList()}
      </Modal>
    );
  }
}

// ------------------------------------------------------------
const mapStateToProps = state => ({
  showCheckpointSelector: state.editor.showCheckpointSelector,
  nodes: state.nodes.talkData.nodes,
  editorItems: {
    nodes: state.activeCanvas.nodes,
    edges: state.activeCanvas.edges,
  },
  canvas: state.canvas,
  nodesState: state.nodes,
  tmpCPName: state.nodes.tmpCPName,
  tmpCPflowId: state.nodes.tmpCPflowId,
  tmpCPaddModel: state.nodes.tmpCPaddModel,
});

// ------------------------------------------------------------
const mapDispatchToProps = dispatch => ({
  addClonedCheckpoint: editorItem =>
    dispatch(activeCanvasActions.addClonedNodeCheckpoint(editorItem)),
  goNewCheckPoint: (name, flowId, editorItem) =>
    dispatch(nodesActions.addNodeCheckPoint(name, flowId, editorItem)),
  closeModal: () => dispatch(editorActions.showCheckpointSelector(false)),
  dispatch,
  deleteNode: id => dispatch(nodesActions.deleteNode(id)),
});

// ------------------------------------------------------------
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropsAPI(CheckpointModal));
